import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InventoryRegionState {
  regions: any[];
  loading: boolean;
}

const initialState: InventoryRegionState = {
  regions: [],
  loading: true,
};

export const inventoryRegionSlice = createSlice({
  name: "inventoryRegion",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setRegions: (state, action: PayloadAction<any[]>) => {
      state.regions = action.payload;
    },
    addRegion: (state, action) => {
      state.regions.unshift(action.payload);
    },
    updateRegion: (state, action) => {
      const { id, inventoryRegion } = action.payload;
      state.regions.every(({ _id }, i) => {
        if (id === _id) {
          state.regions[i] = inventoryRegion;
          return false;
        }
        return true;
      });
    },
    deleteRegion: (state, action) => {
      const id = action.payload;
      state.regions = state.regions.filter((region) => region._id !== id);
    },
  },
});

const inventoryRegionReducer = inventoryRegionSlice.reducer;

export const inventoryRegionActions = inventoryRegionSlice.actions;
export default inventoryRegionReducer;
