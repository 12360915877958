import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InventoryRateState } from ".";

const initialState: InventoryRateState = {
  rates: [],
  loading: true,
};

export const inventoryRateSlice = createSlice({
  name: "inventoryRate",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setRates: (state, action: PayloadAction<any[]>) => {
      state.rates = action.payload;
    },
    addRate: (state, action) => {
      state.rates.unshift(action.payload);
    },
    updateRate: (state, action) => {
      const { id, inventoryRate } = action.payload;
      state.rates.every(({ _id }, i) => {
        if (id === _id) {
          state.rates[i] = inventoryRate;
          return false;
        }
        return true;
      });
    },
    deleteRate: (state, action) => {
      const id = action.payload;
      state.rates = state.rates.filter((rate) => rate._id !== id);
    },
  },
});

const inventoryRateReducer = inventoryRateSlice.reducer;

export const inventoryRateActions = inventoryRateSlice.actions;
export default inventoryRateReducer;
