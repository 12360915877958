import { SelectOption } from "components/atoms/Select";
export { default } from "./Header";

export interface ILink {
  to: string;
  text: string;
  active: string;
  options?: SelectOption[];
}

export const links: ILink[] = [
  { to: "/listings", text: "Listings", active: "listing" },
  { to: "/certificates", text: "Certificates", active: "certificate" },
  { to: "/users", text: "Users", active: "user" },
  { to: "/categories", text: "Categories", active: "categories" },
  { to: "/reviews", text: "Reviews", active: "reviews" },
  { to: "/complaints", text: "Complaints", active: "complaints" },
  { to: "/inquiries", text: "Inquiries", active: "inquiries" },
  { to: "/contacts", text: "Contacts", active: "contacts" },
  { to: "/inventories", text: "Inventories", active: "inventories" },

  // { to: "/realtor", text: "Realtor", active: "realtor" },
];

// view rates
// view booking
// reports
