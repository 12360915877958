import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import authReducer from "./slices/auth";
import userReducer from "./slices/user";
import listingReducer from "./slices/listing";
import categoryReducer from "./slices/category";

import modalReducer from "./slices/modal";
import loaderReducer from "./slices/loader";
import formLoaderReducer from "./slices/formLoader";
import { mapReducer } from "./slices/map/mapSlice";
import { roleReducer } from "./slices/role/roleSlice";
import reviewReducer from "./slices/review/reviewSlice";
import enquireReducer from "./slices/enquire/enquireSlice";
import timeZoneReducer from "./slices/timeZone/timeZoneSlice";
import contactReducer from "./slices/contact/contactSlice";
import certificateSlice from "./slices/certificate/certificateSlice";
import complaintReducer from "./slices/complaint/complaintSlice";
import inventoryItemReducer from "./slices/InventoryItem/InventoryItemSlice";
import inventoryRateReducer from "./slices/InventoryRate/InventoryRateSlice";
import inventoryRegionReducer from "./slices/InventoryRegion/InventoryRegionSlice";

const appReducer = combineReducers({
  form: formReducer,
  map: mapReducer,
  role: roleReducer,
  auth: authReducer,
  user: userReducer,
  listing: listingReducer,
  modal: modalReducer,
  loader: loaderReducer,
  formLoader: formLoaderReducer,
  category: categoryReducer,
  review: reviewReducer,
  complaint: complaintReducer,
  enquire: enquireReducer,
  timeZone: timeZoneReducer,
  contact: contactReducer,
  certificate: certificateSlice,
  inventoryItem: inventoryItemReducer,
  inventoryRate: inventoryRateReducer,
  inventoryRegion: inventoryRegionReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "auth/logout") state = {};
  return appReducer(state, action);
};

export default rootReducer;
