import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InventoryItemState } from ".";

const initialState: InventoryItemState = {
  items: [],
  loading: true,
};

export const inventoryItemSlice = createSlice({
  name: "inventoryItem",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setItems: (state, action: PayloadAction<any[]>) => {
      state.items = action.payload;
    },
    addItem: (state, action) => {
      state.items.unshift(action.payload);
    },
    updateItem: (state, action) => {
      const { id, inventoryItem } = action.payload;
      state.items.every(({ _id }, i) => {
        if (id === _id) {
          state.items[i] = inventoryItem;
          return false;
        }
        return true;
      });
    },
    deleteItem: (state, action) => {
      const id = action.payload;
      state.items = state.items.filter((item) => item._id !== id);
    },
  },
});

const inventoryItemReducer = inventoryItemSlice.reducer;

export const inventoryItemActions = inventoryItemSlice.actions;
export default inventoryItemReducer;
